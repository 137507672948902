import React from 'react'
import CookieConsent from "react-cookie-consent"
import './styles.css'
import { css } from 'theme-ui'

const styles = {
  cookiesWrapper: {
    backgroundColor: "#2d3748",
    boxShadow: `0 0 25px rgba(140,152,164,.25)`,
    zIndex: 50000000,
  },
  buttonStyle: {
    color: "#2d3748",
    fontSize: "15px",
    backgroundColor: "#f4d133",
    borderRadius: "20px",
    padding: "10px 20px"
  },
  footer: {
    flexDirection: [`column-reverse`, `row`],
    justifyContent: `space-between`,
    alignItems: [`center`, `flex-start`],
    py: 5
  }
}

const Cookies = () => {
  return (
      <CookieConsent
          location="bottom"
          buttonText="OK"
          style={styles.cookiesWrapper}
          buttonWrapperClasses={"cookiesButtonWrapper"}
          buttonStyle={styles.buttonStyle}
      >
        Ta strona korzysta z plików Cookies{" "}
        <p style={{ fontSize: "10px" }}>
          Używamy plików cookies w celach świadczenia usług na najwyższym poziomie oraz statystyk zgodnie z 
          <a
              css={css({ mr: 1, textDecoration: "underline", color: 'white' })}
              href="/cookies-policy.docx"
              className={"button-group-link level-1 "}
          >
            Polityką Plików Cookies.
          </a>
          Kontynuowanie przeglądania serwisu bez zmiany ustawień Twojej przeglądarki oznacza zgodę na ich umieszczane w Twoim urządzeniu końcowym.
        </p>
      </CookieConsent>
  )
}

export default Cookies
