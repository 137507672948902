import React from 'react'
import { Box, Label, Checkbox, css } from 'theme-ui'
// import ReCAPTCHA from "react-google-recaptcha";

const FormCheckbox = ({ id, placeholder, ...props }) => {
    // const recaptchaPublicKey = "6LfFzzIgAAAAAF7oIDbJMD9ivUAf923s7mNEFEKA"
    // const [recaptchaValue, setRecaptchaValue] = useState(null);

    /*const recaptcha = placeholder?.text !== "TOS_AGREEMENT"
        ? null
        : (
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <input
                    type="hidden"
                    value={recaptchaValue || ''}
                    disabled
                />

                <ReCAPTCHA
                    hl="pl"
                    sitekey={recaptchaPublicKey}
                    onChange={setRecaptchaValue}
                />
            </div>
        )*/

    return (
        <>
            <Label htmlFor={id}>
                <Box
                    sx={{ position: `relative`, mr: 1, input: { zIndex: `0 !important` } }}
                >
                    <Checkbox
                        id={id}
                        sx={{
                            'input:checked ~ &': {
                                color: 'alpha',
                                display: "none"
                            }
                        }}
                        {...props}
                        value={placeholder?.text === "TOS_AGREEMENT" ? true : undefined}
                        checked={placeholder?.text === "TOS_AGREEMENT" ? true: props.value}
                    />
                </Box>

                { placeholder?.text === "TOS_AGREEMENT"
                    ? (
                        <small css={css({ ml: 2, mt: 1, lineHeight: "1rem" })}>
                            Klikając „Stwórz konto” oświadczasz, że zapoznałeś/zapoznałaś się z <a
                                css={css({ mr: 1, textDecoration: "underline" })}
                                href="/terms-of-service.docx"
                                className={"button-group-link level-1 css-1itgpgl links.nav-light"}
                            >
                                Regulaminem oraz Polityką prywatności
                            </a>
                            i je akceptujesz.
                        </small>
                    )
                    : placeholder?.text
                }
            </Label>

            {/*{recaptcha}*/}
        </>
    )
}

export default FormCheckbox
